html {
    background-color: rgba(0,0,0,0);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

p {
    margin: 20px 50px;
    font-size: 18px;
}

a {
    color: #da711a;
    text-decoration: none;
}

footer {
    text-align: center;
    font-size: medium;
}

#footer {
    display: inline;
    background: black;
    padding: 0px 5px;
    opacity: 0.5;
    border-radius: 4px;
}

#footer:hover {
    opacity: 1;
}

.video {
    background-color: coral;
    width: 720px;
    height: 405px;
    margin: auto;
    margin-left: 48px;
}

#tinBigVideoDe {
    height: 0px;
    overflow: hidden;
    transition-duration: 1s;
}

#harpBigVideoDe {
    height: 0px;
    overflow: hidden;
    transition-duration: 1s;
}

.header {
    background-image: url(assets/img/wood.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    width: 100%;
    height: 152px;
    margin: auto;
    position: relative;
    z-index: 10;
    box-shadow: 0px 7px 11px 0px rgba(0,0,0,0.4);
}

.dropbtn {
    background-color: #f7f7f7;
    color: #272727;
    padding: 10px;
    font-size: 18px;
    border: none;
    min-width: 144px;
  }
  
  .dropdown {
    position: absolute;
    top: 0px;
    right: 0px;
    min-width: 144px;
    zoom: 0.7;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    top: 42px;
    background-color: #f1f1f1;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 0px;
    font-size: 18px;
    padding: 0px;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: #aaa;}

.menu-tabs-container {
    position: relative;
    bottom: 4px;
    left: 100px;
}

.menu-tab {
    float: left;
    cursor: pointer;
    background-color: #f7f7f7;
    margin-right: 10px;
    padding: 10px 15px;
    border-radius: 15px;
    box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.4);
}

.tab-active {
    padding: 10px 15px 40px 15px;
    border-radius: 15px 15px 0px 0px;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
}

.invisible {
    display: none;
}

.spacer {
    margin-bottom: 30px;
}

.songlist-container {
    width: 100%;
}

.songfavolist-container {
    width: 100%;
}

.search-bar {
    margin-bottom: 20px;
}

.songentry {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: background-color 50ms linear;
    border-top: 2px solid #eee;
    padding: 5px;
    padding-left: 10px;
    display: flex;
}

.songentry:hover {
    background: #eeeeee;
}

.songentry__play-button {
    margin-right: 10px;
}

.audiobuttons {
    margin: 5px 0px 15px 0px;
}

.songname {
    font-size: 35px;
}

.favo-star {
    height: 21px;
    position: absolute;
    right: 40px;
    cursor: pointer;
}

.songflag {
    text-orientation: upright;
    writing-mode: vertical-lr;
    width: 80px;
    position: absolute;
    height: 163px;
    top: 240px;
    padding: 7px;
    border-radius: 17px;
    background-color: #eeeeee;
    transition: left 150ms;
    cursor: pointer;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.4);
}

.title {
    background: rgba(0, 0, 0, 0.5);
    margin: auto;
    width: 370px;
    color: #f7f7f7;
    text-align: center;
    height: 72px;
}

.titlename {
    font-size: 44px;
    font-weight: bold;
    padding-top: 10px;
}

.subtitle {
    color: #f7f7f7;
    font-size: 35px;
    margin-top: -42px;
}

.content {
    position: relative;
    z-index: 9;
    width: 100vw;
    height: 100%;
    margin: auto;
    margin-bottom: 20px;
    padding: 32px;
    background-color: #f7f7f7;
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.4);
    min-height: 700px;
}

#spectrum {
    width: 100%;
    height: 30px;
    background: #eee;
}

body {
	font-family: monospace;
	font-size: 20px;
    
    min-height: 100vh;
}

#songTabs {
	font-size: 20px;
}

table td, table th {
    border: 1px solid black;
    text-align: center;
    transition: background-color 100ms linear;
}

#harpscheme {
  background: #eee;
  height: 100px;
  text-align: center;
}

.bigTab {
	color: red;
	font-size: 30px;
}

.songContainer {
}

.tab-container {
    zoom: 80%;
}

.stroke {
    float: left;
    padding: 8px;
    margin: 2px;
    background-color: #eee;
}

.noteContainer {
    float: left;
    min-height: 44px;
}

.note1 {
    width: 192px;
}

.note2 {
    width: 96px;
}

.note4 {
    width: 48px;
}

.note8 {
    width: 24px;
}

.note16 {
    width: 12px;
}

.note32 {
    width: 6px;
}

.note {
        padding-right: 3px;
}

.song-bar {
    background-color: red;
    display: block;
    height: 7px;
    border-radius: 5px;
    width: 0%;
    transition: background-color 200ms linear;
    position: relative;
    z-index: 3;
}

.song-bar-back {
    background-color: #8a8a8a;
    display: block;
    height: 7px;
    border-radius: 5px;
    width: 100%;
    transition: background-color 200ms linear;
    position: relative;
    top: -7px;
}

.old {
    background-color: #dddddd;
}

.pause {
    background-color: #bfa29c;
}

.songText {
    font-size: 12px;
}

#editor {
    display: none;
    margin-top: 20px;
}

#debug {
    display: none;
}

.tin-tab {
    height: 80px;
    margin: 0px 0px -12px 5px;
}

input[type="range"] { 
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 40px;
    width: 300px;
    cursor: pointer;
    border-radius: 4px; /* iOS */
}

input[type="range"]:focus {outline:0;}

::-webkit-slider-runnable-track {
    background: #ddd;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px; /* 1 */
    height: 40px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw dodgerblue;
    border: 1px solid #999; /* 1 */
}

input[type=range]::-moz-range-track {
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 40px;
    width: 300px;
    cursor: pointer;
    border-radius: 5px; /* iOS */
}

input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    width: 20px; /* 1 */
    height: 40px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw dodgerblue;
    border: 1px solid #999; /* 1 */
}

#speedValue {
    position: relative;
    display: inline-block;
    top: -13px;
    left: -290px;
    color: white;
    font-weight: bold;
    user-select: none;
    pointer-events: none;
    width: 115px;
    font-size: 18px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 72px;
    top: -17px;
    height: 40px;
    left: -111px;
  }

#soundCheckIcon {
    position: relative;
    z-index: 2;
    left: -161px;
    top: -11px;
    color: white;
    user-select: none;
    pointer-events: none;
}

#loopCheckIcon {
    position: relative;
    z-index: 2;
    left: -183px;
    top: -11px;
    color: white;
    user-select: none;
    pointer-events: none;
}

#loopLabel {
    left: -133px;
}
  
  .switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 13px;
  }
  
  .switch-slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 13px;
  }
  
  input:checked + .switch-slider {
    background-color: #2196F3;
  }
  
  input:checked + .switch-slider:before {
    -webkit-transform: translateX(47px);
    -ms-transform: translateX(47px);
    transform: translateX(47px);
  }

  .instrumenthub {
    width: 330px;
    margin: auto;
    margin-top: -11px;
    margin-bottom: -49px;
    zoom: 0.8;
  }

  .instrumenthub__button {
    float: left;
    background-color: #eeeeee;
    border-radius: 69px;
    margin: 10px;
    height: 90px;
    cursor: pointer;
    box-shadow: 0px 7px 0px 3px rgba(0,0,0,0.4);
  }

  .instrumenthub__button-active {
    float: left;
    background-color: #929292;
    border-radius: 69px;
    margin: 10px;
    height: 90px;
    cursor: pointer;
    position: relative;
    top: 10px;
  }

  .instrumenthub__icon {
    width: 90px;
    padding: 10px;
    user-select: none;
    pointer-events: none;
  }

  .stroke-container {
    position: relative;
    top: -2px;
  }

  .stroke-count {
    font-size: 15px;
    margin-bottom: -4px;
  }

  .stroke-divider {
    margin: 0;
    background: black;
  }

  .stroke-length {
    font-size: 15px;
  }

  .songlist-name {
    margin-left: 12px;
    font-size: 18px;
  }

  .paypal-button{
    background-color: #fff;
    height: 60px;
    padding: 10px;
    border-radius: 10px;
    margin-top: 25px;
    box-shadow: 0px 2px 3px 1px rgba(0,0,0,0.4);
    margin-right: 50px;
    transition-duration: 0.25s;
  }

  .paypal-button:hover {
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.4);
  }

  .paypal-button:active {
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.4);
  }


  .learnimg {
      margin:auto;
      height: 200px;
      display: block;
  }

  .tintabsbuttons {
    margin: auto;
    display: table;
  }

  .tintabsbutton {
    margin: 5px;
    max-height: 150px;
    cursor: pointer;
  }



@media (orientation:landscape) {
    body {
        background-image: url(assets/img/metal.jpg);
    }

    .content {
        width: 870px;
    }

    .header {
        width: 1000px;
    }
}

@media (orientation:portrait) {
    body {
    }

    p {
        font-size: 38px;
    }

    .songlist-name {
        font-size: 38px;
        margin-top: 19px;
    }

    .songentry__play-button {
        height: 87px;
        min-width: 87px;
        font-size: 34px;
    }

    .favo-star {
        height: 84px;
    }

    .instrumenthub {
        zoom: 1.5;
        margin-bottom: -16px;
    }

    .searchbar {
        font-size: 37px;
    }

    .stroke {
        zoom: 2.2;
    }

    .button {
        font-size: 37px;
    }

    input[type="range"] { 
        height: 80px;
    }

    .switch-slider:before {
        height: 80px;
        left: 0px;
        bottom: 2px;
    }

    .switch {
        top: -57px;
        height: 80px;
    }

    ::-webkit-slider-thumb {
        height: 80px;
        width: 80px;
    }

    input:checked + .switch-slider:before {
        -webkit-transform: translateX(55px);
        -ms-transform: translateX(55px);
        transform: translateX(55px);
      }
    
}